import React from "react";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Button,
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Form,
  Image
} from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H2, H4, Span } from "../components/Typography/Typography";
import talksLogo from "../images/sf-talks-logo.png";

const IndexPage = () => (
  <PageTransition>
    <Layout>
      <SEO title="Home" />
      <Container>
        <Jumbotron as="header" className="sf-home d-flex align-items-center">
          <Row>
            <Col lg={8}>
              <H4>
                Since 2012, we organize events to help you develop your tech
                skills, share your opinion, showcase what you’re working on, and
                check out the epic things others are building.
              </H4>
              <P>
                <Button as={Link} to="/about" variant="light">
                  Get involved
                </Button>
                <Button as={Link} to="/about" variant="outline-light">
                  Find out more
                </Button>
              </P>
            </Col>
          </Row>
        </Jumbotron>
      </Container>

      <Section className="sf-about-us">
        <Container>
          <Row>
            <Col>
              <H2>About us</H2>
              <P className="text-center">
                Softbinator Foundation is a non-profit organization that through
                interaction, interest and passion raises
                <br /> performance standards throughout Romania’s IT community.
              </P>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col lg={6}>
              <Card className="our-mission px-5 py-5">
                <Card.Body>
                  <Card.Title>Our Mission</Card.Title>
                  <Card.Text>
                    Our mission is to create the largest IT community in Romania
                    and contribute to the growth of excellence through
                    professional development events, networking and support for
                    social projects.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="our-vision px-5 py-5">
                <Card.Body>
                  <Card.Title>Our Vision</Card.Title>
                  <Card.Text>
                    Our vision is a big and strong IT community that will help
                    Social Impact Startups from the tech industry to make a
                    better world.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                as={Link}
                to="/about"
                variant="outline-dark"
                className="mt-3"
              >
                Find out more
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-what-we-do">
        <Container fluid>
          <Row>
            <Col>
              <H2 className="mb-5">What we do</H2>
            </Col>
          </Row>

          <Container className="container-medium">
            <Row>
              <Col md={4} className="d-flex justify-content-center">
                <Image
                  className="img-fluid"
                  src={talksLogo}
                  alt="Softbinator Talks"
                />
              </Col>
              <Col md={8}>
                <P>
                  We created an event named “Talks” with purpose to gather
                  awesome people on a biweekly basis, to talk about what’s new
                  or interesting in IT. The concept was started in February
                  2013, and since then, we couldn’t stop. Usually, there are two
                  speakers per event who, for 30 minutes talk about their ideas,
                  new gizmos or technologies, solutions for common yet difficult
                  issues, followed by a short Q&A session.
                </P>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <ListGroup horizontal="lg" className="my-3">
                <ListGroup.Item className="d-flex align-items-center rounded-0 sessions">
                  <P className="d-flex flex-column">
                    <Span>133</Span> talks sessions
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 speakers">
                  <P className="d-flex flex-column">
                    <Span>251</Span> speakers
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 workshops">
                  <P className="d-flex flex-column">
                    <Span>7</Span> workshops
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 hackathons">
                  <P className="d-flex flex-column">
                    <Span>2</Span> hackathons
                  </P>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                as={Link}
                to="/talks"
                variant="outline-light"
                className="mt-3"
              >
                Find out more
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-get-notified">
        <Container>
          <Row>
            <Col>
              <H2>Be part of our community</H2>
              <P className="text-center">
                Subscribe and be the first to hear about our next event
              </P>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Form
                className="get-notified"
                action="https://softbinator.us2.list-manage.com/subscribe/post?u=a8f550154253f6574139ed3ae&amp;id=7d076fb650"
                method="POST"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                inline
              >
                <Form.Label htmlFor="email" srOnly>
                  Name
                </Form.Label>
                <Form.Control
                  className=""
                  id="mce-EMAIL"
                  name="EMAIL"
                  placeholder="Enter your e-mail address"
                  required
                />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <Form.Control
                    className=""
                    name="b_a8f550154253f6574139ed3ae_7d076fb650"
                    tabIndex="-1"
                    defaultValue=""
                  />
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  className=""
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Subscribe
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-events">
        <Container className="container-small">
          <Row>
            <Col>
              <H2 className="mb-5">Events</H2>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="upcoming-events">
              <H4>Upcoming events</H4>
              <P>Join our future events on Facebook!</P>
            </Col>

            <Col md={6} className="past-events">
              <H4>Past events</H4>
              <P>
                <a
                  href="https://www.youtube.com/user/softbinator"
                  target="_blank"
                  rel="noreferrer"
                >
                  Missed our event? Don’t worry, you can view them here.
                </a>
              </P>
            </Col>

            <Col>
              <iframe
                name="f3d22f42d1fe25"
                width="340px"
                height="410px"
                data-testid="fb:page Facebook Social Plugin"
                title="fb:page Facebook Social Plugin"
                frameBorder="0"
                allowtransparency="true"
                allowFullScreen={true}
                scrolling="no"
                allow="encrypted-media"
                src="https://www.facebook.com/v3.2/plugins/page.php?adapt_container_width=true&amp;app_id=396061880449797&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df79e9297dde8e%26domain%3Dfoundation.softbinator.com%26origin%3Dhttps%253A%252F%252Ffoundation.softbinator.com%252Ff2e2671e0f08608%26relation%3Dparent.parent&amp;container_width=1110&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fsoftbinator%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=events"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-help-community">
        <Container className="container-medium">
          <Row>
            <Col>
              <H2>Help the community grow!</H2>
              <P className="text-center">
                Your contribution helps the community become self-sustainable
                <br /> and grow at a faster pace, bringing people together.
              </P>
            </Col>
          </Row>

          <Row className="sf-how my-5">
            <Col md={6} className="partner">
              <H4 className="corporate">Become our corporate partner</H4>
              <P>
                Benefit from our know-how, support and online community to make
                your company better known locally and nationally.
              </P>
              <Button
                as={Link}
                to="/companies"
                variant="light"
                className="mt-3"
              >
                Let's partner
              </Button>
            </Col>

            <Col md={6} className="individual">
              <H4 className="individuals">Individuals</H4>
              <P>
                Be a speaker or donate online to bring your contribution to help
                the community be sustainable and grow.
              </P>
              <Button
                as={Link}
                to="/individuals"
                variant="light"
                className="mt-3"
              >
                Get involved
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  </PageTransition>
);

export default IndexPage;
